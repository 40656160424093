<template>
    <div id="userList">

        <div class="menu_content">
            <div class="menu_content_title">
                <h4>用户管理</h4>
            </div>

            <div class="menu_content_content">
                <div class="menu_left">
                    <!-- <el-input placeholder="搜索菜单名称" v-model="filterText" suffix-icon="el-icon-search" style="height: 32px; margin-bottom: 20px"></el-input> -->

                    <el-tree
                      class="filter-tree"
                      v-if="treeData.length"
                      :data="treeData"

                      :check-strictly="true" 
                      :highlight-current="true"
                      :props="defaultProps"
                      default-expand-all
                      :filter-node-method="filterNode"
                      @node-click="chooseNode"
                      ref="tree">
                      <span class="custom-tree-node" style="font-size: 14px" slot-scope="{node,data}">
                        <span class="el-icon-office-building" style="margin-right: 4px"></span><span>{{data.orgName}}</span>

                      </span>
                    </el-tree>
                </div>

                <div class="menu_rights">
                    <div>
                        <el-button type="primary" @click="addUser" style="height: 32px; padding: 0 12px">新增用户</el-button>
                        <el-input style="width: 250px; height: 32px; float: right" @keyup.enter.native="search" v-model="name" placeholder="请输入用户名或编号搜索" suffix-icon="el-icon-search"></el-input>
                        <!-- <el-button type="primary" plain style="height: 32px; padding: 0 12px">批量导入</el-button>
                        <el-button type="primary" plain style="height: 32px; padding: 0 12px">批量导出</el-button> -->
                    </div>
                    

                    <h6 v-if="orgName">{{orgName}}</h6>
                    <div style="margin-top: 14px">
                        <el-table border :data="tableData" style="width: 100%">
                            <el-table-column border-column
                              fixed="left"
                              label="序号"
                              type="index"
                              width="70">
                            </el-table-column>
                            <!-- <el-table-column border-column prop="name" ></el-table-column> -->
                            <el-table-column min-width="140" border-column prop="userBO.userName" label="用户名称"></el-table-column>
                            <el-table-column border-column min-width="120" prop="userBO.userChineseName" label="真实姓名"></el-table-column>
                            
                            <el-table-column border-column min-width="140" prop="manageOrgName" label="所属架构"></el-table-column>
                            <el-table-column border-column min-width="120" prop="employNum" label="员工编号"></el-table-column>
                            <el-table-column border-column min-width="120" prop="entryTime" label="入职时间"></el-table-column>
                            <el-table-column border-column min-width="120" prop="userBO.email" label="邮箱"></el-table-column>
                            <el-table-column border-column min-width="120" prop="userBO.mobile" label="联系方式"></el-table-column>
                            <!-- <el-table-column border-column prop="name" label="角色"></el-table-column> -->
                            <el-table-column border-column min-width="120" prop="userBO.userStatus" align="center" label="用户状态">
                              <template slot-scope="scope">
                                    <el-switch
                                        @change="changeStatus(scope.row)"
                                        v-model="scope.row.userBO.userStatus == '1' ? true : false"
                                        active-color="#13ce66"
                                        inactive-color="#ff4949">
                                    </el-switch>

                                </template>
                            </el-table-column>
                            <el-table-column border-column prop="name" min-width="120" fixed="right" align="center" label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="editUser(scope.row)" type="text" size="small">编辑</el-button>
                                    <el-popconfirm
                                        icon="el-icon-info"
                                        icon-color="red"
                                        @confirm="delUser(scope.row.id)"
                                        title="是否确认删除用户?"
                                        >
                                        <el-button slot="reference" type="text" style="margin-left: 12px" size="small">删除</el-button>
                                    </el-popconfirm>
                                    
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                    <div style="margin-top: 12px; float: right">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageInfo.pageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageInfo.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>



        <el-dialog :title="dialogTitle" width="40%" :visible.sync="dialogFormVisible">
            <el-form :model="form" ref="cdForm" style="width: 80%; margin: 20px auto 0;" :rules="rules" label-width="80px">
                <el-form-item label="用户名" prop="userBO.userName">
                    <el-input v-model="form.userBO.userName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="真实姓名" prop="userBO.userChineseName">
                    <el-input v-model="form.userBO.userChineseName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="员工编号">
                    <el-input v-model="form.employNum" autocomplete="off"></el-input>
                </el-form-item>
            
                <el-form-item label="入职时间">
                     <el-date-picker
                        v-model="form.entryTime"
                        type="date"
                        placeholder="选择入职时间">
                    </el-date-picker>
                </el-form-item>
                
                <el-form-item label="所属机构" prop="manageOrgName">
                    <el-popover
                        placement="bottom"
                        width="414"
                        trigger="click"
                        >
                        <el-tree :data="treeData" v-if="treeData.length" default-expand-all :props="defaultProps" @node-click="handleNodeClick"></el-tree>
                        
                        <el-input readonly="" v-model="form.manageOrgName" autocomplete="off" slot="reference"></el-input>
                    </el-popover>
                </el-form-item>

                

                <el-form-item label="角色">
                    <el-select v-model="form.roleIdList" multiple placeholder="请选择">
                      <el-option
                        v-for="item in roleList"
                        :key="item.id"
                        :label="item.roleName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="邮箱">
                    <el-input v-model="form.userBO.email" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item label="联系方式">
                    <el-input v-model="form.userBO.mobile" autocomplete="off"></el-input>
                </el-form-item>
                
                <el-form-item label="状态" prop="status">
                    <el-radio v-model="form.userBO.userStatus" label="1">启用</el-radio>
                    <el-radio v-model="form.userBO.userStatus" label="2">停用</el-radio>
                </el-form-item>
                
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="add">确 定</el-button>
            </div>
      </el-dialog>
    </div>
</template>

<script>
// import json from '../../assets/json/icon.json'
export default {
    data() {
      return {
        dialogTitle: '新增用户',
        dialogFormVisible: false,
        json: require('../../assets/json/icon.json'),
        orgName: null,
        name: '',
        form: {
            "entryTime":"",
            "employNum":"",
            "manageOrgId":"",
            "manageOrgName":"",
            "userBO":{
                "userName":"",
                "userChineseName":"",
                "mobile":"",
                "email":"",
                "userStatus":"1",
                "userType":"userTpye_opera_normal"
            },
            "roleIdList": []
        },
        rules: {
          'userBO.userName': {required: true, message: '请输入用户名', trigger: 'blur'},
          'userBO.userChineseName': {required: true, message: '请输入真实姓名', trigger: 'blur'},
          manageOrgName: {required: true, message: '请选择组织机构', trigger: 'blur'},
        },
        roleList: [],
        pageInfo: {
          pageNo: 1,
          pageSize: 10,
          total: 0
        },
        orgid: null,
        filterText: '',
        tableData: [],
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'orgName'
        }
      };
    },
    watch: {
      
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    mounted(){
      this.qryRole();
      this.qryOrg();
      this.search();
      if (window.innerHeight){
            var winHeight = window.innerHeight;
        }
        else if ((document.body) && (document.body.clientHeight)){
            var winHeight = document.body.clientHeight;
        }
        document.getElementById("userList").style.minHeight = (winHeight-90)+'px';
    },
    methods: {
      qryOrg(){
        this.qa.orgList({}).then((res => {
          this.treeData = this.FWZX.buildTree(res.data, 'orgCode', 'parentOrgCode');
          console.log(this.treeData)
        }))
      },  
      qryRole(){
        this.qa.queryFindList({}).then(res => {
          this.roleList = res.data;
        })
      },
      search(){
        let params = {
           "pageSize": this.pageInfo.pageSize,
           "pageNo": this.pageInfo.pageNo,
           employNumOrName: this.name
        //    "userBO":{
        //        "userChineseName": this.name
        //    }
        }
        this.qa.queryUser(params).then((res) => {
          if(res.respCode == "0000") {
            this.tableData = res.data;
            this.pageInfo.total = res.total;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      //新增按钮
      addUser(){
        this.dialogTitle = "新增用户";
        this.form = {
            "entryTime":"",
            "employNum":"",
            "manageOrgId":"",
            "manageOrgName":"",
            "userBO":{
                "userName":"",
                "userChineseName":"",
                "mobile":"",
                "email":"",
                "userStatus":"1",
                "userType":"userTpye_opera_normal"
            },
           "roleIdList": []
        };
        this.dialogFormVisible = true;
      },
      //选中左侧树
      chooseNode(data){
        // this.$set("")
        this.orgid = data.id;
        this.orgName = data.orgName;
        this.qa.queryUser({manageOrgId: data.id, pageNo: this.pageInfo.pageNo, pageSize: this.pageInfo.pageSize}).then((res) => {
          if(res.respCode == "0000") {
            this.tableData = res.data;
            this.pageInfo.total = res.total;
          } else {
            this.$message.error(res.msg);
          }
        })
      },
      editUser(row){
        this.dialogTitle = "编辑用户";
        this.form = {
            "id": row.id,
            "userId": row.userId,
            "entryTime": row.entryTime,
            "employNum": row.employNum,
            "manageOrgId": row.manageOrgId,
            "manageOrgName": row.manageOrgName,
            "userBO":{
                "userName": row.userBO.userName,
                "userChineseName": row.userBO.userChineseName,
                "mobile": row.userBO.mobile,
                "email": row.userBO.email,
                "userStatus": row.userBO.userStatus,
                "userType":"userTpye_opera_normal"
            },
            "roleIdList": []
        };
        this.qa.queryUserRolesByUserId({userId: row.userId}).then(res => {
          for(let i = 0; i < res.data.length; i++) {
            res.data[i] = parseInt(res.data[i]);
          }
          this.form.roleIdList = res.data;
          this.dialogFormVisible = true;
        })
        
      },
      changeStatus(row){
        this.qa.userSwitch({userId: row.userId}).then(res => {
          this.qa.queryUser({manageOrgId: this.orgid, pageNo: this.pageInfo.pageNo, pageSize: this.pageInfo.pageSize}).then((res) => {
            if(res.respCode == "0000") {
              this.tableData = res.data;
              this.pageInfo.total = res.total;
            } else {
              this.$message.error(res.msg);
            }
          })
        })
      },
      add(){
        this.$refs.cdForm.validate((valid) => {
          if (valid) {
            if(this.dialogTitle == "新增用户") {
              this.qa.addUser(this.form).then((res) => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '用户新增成功！',
                        type: 'success'
                    });
                    this.search();
                    this.dialogFormVisible = false;
                } else {
                  this.$message.error(res.msg)
                }
              })
            } else {
              this.qa.editUser(this.form).then((res) => {
                if(res.respCode == "0000") {
                    this.$message({
                        message: '用户编辑成功！',
                        type: 'success'
                    });
                    this.search();
                    this.dialogFormVisible = false;
                } else {
                  this.$message.error(res.msg)
                }
              })
              
            }
            this.qa.queryUser({manageOrgId: this.orgid, pageNo: this.pageInfo.pageNo, pageSize: this.pageInfo.pageSize}).then((res) => {
              if(res.respCode == "0000") {
                this.tableData = res.data;
                this.pageInfo.total = res.total;
              } else {
                this.$message.error(res.msg);
              }
            })
          } else {
            return false;
          }
        })
        
      },
      delUser(id){
        this.qa.delUser({id: id}).then(res => {
          if(res.respCode == "0000") {
            this.$message({
                message: '用户删除成功！',
                type: 'success'
            });
            this.qa.queryUser({manageOrgId: this.orgid, pageNo: this.pageInfo.pageNo, pageSize: this.pageInfo.pageSize}).then((res) => {
              if(res.respCode == "0000") {
                this.tableData = res.data;
                this.pageInfo.total = res.total;
              } else {
                this.$message.error(res.msg);
              }
            })
          } else {
            this.$message.error(res.msg)
          }
        })
      },

      handleNodeClick(data){
        // console.log(data)
        // "manageOrgId": row.manageOrgId,
        //     "manageOrgName": row.manageOrgName,
        this.form.manageOrgId = data.id;
        this.form.manageOrgName = data.orgName;

      },
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      handleSizeChange(page){
          this.pageInfo.pageSize = page;
          this.search();
      },
      handleCurrentChange(page) {
          this.pageInfo.pageNo = page;
          this.search();
      }
    },

}
</script>

<style scoped>
.menu_content >>> .el-input__inner {
  height: 32px;
}
.menu_content >>> .el-input__icon {
  line-height: 32px;
}
  .menu_title {
    width: 100%;
    height: 68px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .menu_content {
      width: 100%;
      background: #fff;
      margin-top: 12px;
  }
  .menu_content_title {
      height: 50px;
      border-bottom: 1px solid #EFEFEF;
      position: relative;

  }
  .menu_content_title >h4 {
    position: absolute;
    color: #1D1E1F;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    left: 24px;
    bottom: 0;
  }
  .menu_content_content {
      display: flex;
  }
  .menu_left {
      padding: 10px 24px;
      width: 260px;
      flex-shrink: 0;
      border-right: 1px solid #EFEFEF;
  }
  .menu_left >>> .el-input__inner {
      height: 32px;
  }
  .menu_rights {
      width: calc(100% - 260px);
      flex-shrink: 0;
      padding: 10px 24px;
  }
  .menu_rights >h6 {
      font-size: 16px;
      color: #1D1E1F;
      margin-top: 30px;
  }
  .menu_rights >h6 a {
      font-size: 14px;
      color: #1991FC;
      font-weight: 400;
      margin-left: 24px;
  }
  .menu_rights >p {
      margin-top: 10px;
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
  }
  .menu_rights >p >span {
      margin-right: 20px;
  }
</style>